import React, { useState, useEffect, useContext, useRef } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
// components
import { useAuthContext } from "../hooks/useAuthContext"
import usePage from "../hooks/usePage"
import useUser from "../hooks/useUser"
import Widgets from "../helpers/widgets"
import { useDocument } from "../hooks/useDocument"
import { StateContext } from "../helpers/StateProvider"
import ReactToPrint from "react-to-print"
import { Share } from "../components/LisitngDetails/ListingDetails.styles"

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share"
import Faceboook from "../assets/icons/facebook-share.svg"
import Twitter from "../assets/icons/twitter-share.svg"
import Linkedin from "../assets/icons/linkedin-share.svg"
import Whatsapp from "../assets/icons/whatsapp-icon.svg"
import telegram from "../assets/icons/telegram_logo_circle_icon.svg"
import email from "../assets/icons/email-envelop.svg"
const IndexPage = props => {
  const { user, authIsReady } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [pageWidgets, setPageWidgets] = usePage("home")
  const [arabic, setArabic] = useState(false)
  const componentRef = useRef()
  const currentUrl = window.location.href
  useEffect(() => {
    setLoading(false)
  }, [pageWidgets])
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {authIsReady && (
        <>
          <>
            <Seo title="house-decoration" ref={componentRef} />
            <div
              className={
                language == false
                  ? "container cards_about-us"
                  : "text-right container cards_about-us"
              }
            >
              <h4>
                {language == false ? "Dear  customer," : "عزيزنا العميل،"}
              </h4>
              <p>
                {language == false
                  ? "Our team is always ready to visit your property free of charge and ask you about your requirements and demands. "
                  : "فريقنا جاهز دائمًا لزيارة مكان الإقامة الخاص بك مجانًا وسؤالك عن متطلباتك ومطالبك."}
              </p>
              <p>
                {language == false
                  ? "DOT VIP invite you to change your life style. Why not? Spoil your property through reconfiguration, decoration, and modern living styles."
                  : "تدعوك DOT VIP لتغيير أسلوب حياتك. لما لا؟ دلل ممتلكاتك من خلال إعادة التشكيل والديكور وأنماط المعيشة الحديثة."}
              </p>
              <p>
                {language == false
                  ? "However, kindly ask for an appointment,"
                  : "يرجى طلب موعد ،"}
              </p>
              <p>
                {language == false
                  ? "Just call +961 3 948 739 daily between 09:00 AM and 05:00 PM except on the weekend (Saturday and Sunday)"
                  : "فقط اتصل على الرقم 03948739  يوميًا بين الساعة 09:00 صباحًا و 05:00 مساءً باستثناء عطلة نهاية الأسبوع (السبت والأحد)"}
              </p>
              <p>{language == false ? "Regards" : "مع تحيات فريق العمل"}</p>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse1.png?alt=media&token=d09883c4-f87f-4d76-a300-b393cddc32ec"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse2.png?alt=media&token=f5fe9824-c035-442e-aca7-fdb49ccd2d7b"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse3.png?alt=media&token=59089d4c-ff73-4137-a01c-08ad3091dc40"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse4.png?alt=media&token=6cd9eadc-68ff-46b5-bcd2-4880f7993e0f"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse5.png?alt=media&token=3074ef13-44c6-4a28-96f0-3584532523e0"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse6.png?alt=media&token=e0f05083-69c4-4f55-b571-61c9b6466a9e"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse7.png?alt=media&token=323020c9-8a14-4bd5-bc63-99493be68f1f"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse8.png?alt=media&token=0e758e32-59a4-49f5-9ca8-bed872a55873"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse9.png?alt=media&token=ec375889-6c8f-469b-a80a-290fb79508b1"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse10.png?alt=media&token=3b827721-dece-415a-ba49-696294cb13cb"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse11.png?alt=media&token=b2ca9fa7-e849-45d6-bde4-b28d39913860"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse12.png?alt=media&token=f4b9b83c-a9c2-427f-9feb-993f2a98afc4"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse13.png?alt=media&token=f961bcef-531a-4656-8785-82a3d28c4ed5"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse14.png?alt=media&token=113605cb-05de-4a59-a6e2-8a0827735682"></img>
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse15.png?alt=media&token=afba57fd-2b1d-4212-80ba-cd22db22373e"></img>
                </div>
                <div className="d-flex flex-column ">
                  <img src="https://firebasestorage.googleapis.com/v0/b/opddev-51cfb.appspot.com/o/files%2Fimagehouse16.png?alt=media&token=63c8d850-2e7e-4278-9e98-a0c3777df486"></img>
                </div>
              </div>
              <Share>
                <div className="text">
                  {arabic == false ? "Share on" : "مشاركه فى"}
                </div>
                <div className="socials">
                  <FacebookShareButton
                    url={currentUrl}
                    title="Facebook"
                    hashtag="#property"
                  >
                    <img src={Faceboook} alt="facebook icon" />
                  </FacebookShareButton>
                  <TwitterShareButton url={currentUrl} title="Twitter">
                    <img src={Twitter} alt="twitter icon" />
                  </TwitterShareButton>
                  <LinkedinShareButton url={currentUrl} title="Linkedin">
                    <img src={Linkedin} alt="linkedin icon" />
                  </LinkedinShareButton>
                  <WhatsappShareButton url={currentUrl} title="Whatsapp">
                    <img src={Whatsapp} alt="whatsapp icon" />
                  </WhatsappShareButton>
                  <TelegramShareButton url={currentUrl} title="telegram">
                    <img
                      src={telegram}
                      alt="whatsapp icon"
                      style={{ width: "64px" }}
                    />
                  </TelegramShareButton>
                  <EmailShareButton
                    url={currentUrl}
                    subject="share page"
                    body={"this is link shared"}
                  >
                    <img
                      src={email}
                      alt="whatsapp icon"
                      style={{ width: "50px" }}
                    />
                  </EmailShareButton>
                </div>
              </Share>
            </div>
            <ReactToPrint
              content={() => componentRef.current}
              trigger={() => (
                <button className="btn btn-primary">Print to PDF!</button>
              )}
            />
          </>
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allNews(limit: 3, sort: { fields: created_at }) {
      edges {
        node {
          description
          featured_image
          id
          overview
          title
          topic_title
          topic
          created_at
          localImage {
            childImageSharp {
              fixed(width: 300, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allEvents(limit: 2, sort: { fields: date }) {
      edges {
        node {
          date
          city
          address
          name
          id
          country
          thumbnail {
            url
          }
        }
      }
    }
    allPage(filter: { name: { eq: "home" } }) {
      edges {
        node {
          name
          title
          id
          description
          widgets {
            name
            type
          }
        }
      }
    }
    allHero {
      edges {
        node {
          image {
            url
          }
          localImage {
            childImageSharp {
              fixed(width: 2000, height: 800) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          id
          title
          title_ar
          paragraph
          paragraph_ar
          tagline
          tagline_ar
          link {
            text_ar
            text
            url
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget: allWidget {
      edges {
        node {
          description
          id
          image

          link_text
          link
          name
          title
          type
        }
      }
    }
    widget1: widget(name: { eq: "how-we-works" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
    }
    widget2: widget(name: { eq: "property-type" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget3: widget(name: { eq: "explore-properties" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget4: widget(name: { eq: "our-latest-news" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget5: widget(name: { eq: "it-is-time-to-expect-more" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget6: widget(name: { eq: "our-latest-events" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default IndexPage
